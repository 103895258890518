<!--
 * @Author: SongYijie
 * @Date: 2020-03-09 09:42:27
 * @LastEditors: SongYijie
-->
<template>
  <div class="confirm-apply-user">
    <div class="action-wrap">
      <p class="label">地区</p>
      <p class="value">{{comfirmInfo.areaLabel}}</p>
    </div>
    <div class="action-wrap">
      <p class="label">证件类型</p>
      <p class="value">{{comfirmInfo.cardLabel}}</p>
    </div>
    <div class="action-wrap">
      <p class="label">真实姓名</p>
      <p class="value">{{comfirmInfo.userName}}</p>
    </div>
    <div class="action-wrap">
      <p class="label">证件号码</p>
      <p class="value">{{comfirmInfo.idNum}}</p>
    </div>
    <div class="action-wrap">
      <p class="label">性别</p>
      <p class="value">{{comfirmInfo.userSexLabel}}</p>
    </div>
    <div class="action-wrap">
      <p class="label">出生日期</p>
      <p class="value">{{comfirmInfo.userBirthday}}</p>
    </div>
    <p class="title-info">证件照片</p>
    <img :src="comfirmInfo.userIdcardImg1 + '/watermark_text'" alt="upload" class="upload" />
    <p class="title-info">审核记录</p>
    <div class="review-info">
      <div class="review-item cancel-info" v-if="comfirmInfo.state === 2">
        <p>{{comfirmInfo.reviewOpinion}} </p>
        <p>{{comfirmInfo.reviewDate}}</p>
      </div>
      <div class="review-item waiting-info">
        <p>已提交审核, 工作人员将在一个工作日内审核, 奋斗的号角即将吹响! </p>
        <p>{{comfirmInfo.createDate}}</p>
      </div>
    </div>
    <div class='footer-bt bg-white' v-if="comfirmInfo.state === 2">
      <mt-button type='danger' size='large' @click="handleReview">重新认证</mt-button>
    </div>
  </div>
</template>

<script>
import { getApplyUserInfo } from '@api/user';
import { Toast, Indicator } from 'mint-ui';
import { handleGetType, ApplyUserArea, ApplyUserIdCardType, UserSexType, formatDate } from '@utils/common';
export default {
  components: {},
  data() {
    return {
      comfirmInfo: {}
    };
  },
  mounted() {
    Indicator.open('加载中...');
    let timer = setTimeout(() => {
      Indicator.close();
    }, 20000)
    getApplyUserInfo().then(res => {
      clearTimeout(timer);
      Indicator.close();
      if (res.code === 200) {
        const data = res.data || {};
        if (data.userState === 2) {
          this.$router.replace('/taskCenter');
          return;
        }
        data.areaLabel = handleGetType(data.area, ApplyUserArea);
        data.cardLabel = handleGetType(data.idCardType, ApplyUserIdCardType);
        data.userSexLabel = handleGetType(data.userSex, UserSexType);
        data.createDate = formatDate(new Date(data.createDate), "yyyy-MM-dd HH:mm:ss");
        this.comfirmInfo = data
      } else {
        Toast(res.message);
      }
    })
  },
  methods: {
    handleReview() {
      // this.$router.replace('/user/selectArea');
      this.$router.replace('/user/applyUser');
    }
  }
};
</script>
<style lang='less' scoped>
* {
  box-sizing: border-box;
  margin: 0;
}
.confirm-apply-user {
  background-color: #ffffff;
  padding: 3.2vw;
  padding-bottom: 26.6667vw;
}
.action-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13.3333vw;
  border-bottom: 0.1333vw solid rgb(240, 240, 240);
}
.label {
  width: 40%;
  color: rgb(137, 137, 137);
  font-size: 4.2667vw;
}
.value {
  width: 60%;
  font-size: 4.2667vw;
  text-align: right;
  color: #666666;
}
.title-info {
  height: 13.3333vw;
  line-height: 13.3333vw;
  background-color: rgb(250, 250, 250);
  margin: 0 -3.2vw;
  padding: 0 3.2vw;
}
.upload {
  width: 82.9333vw / 1.2;
  height: 49.3333vw / 1.2;
  display: block;
  margin: 3.2vw auto;
  object-fit: contain;
}
.cancel-info {
  border-bottom: 1px solid #cccccc;
  &::before {
    background-image: url('./../../assets/images/user/cancel.png');
  }
}
.waiting-info {
  &::before {
    background-image: url('./../../assets/images/user/wait.png');
  }
}
.review-item {
  margin-top: 3.2vw;
  padding-left: 5.8667vw;
  padding-bottom: 5.2vw;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 5.8667vw;
    height: 5.8667vw;
    background-size: cover;
  }
  p {
    line-height: 5.8667vw;
    &:nth-of-type(1) {
      color: rgb(47, 47, 47);
    }
    &:nth-of-type(2) {
      color: rgb(144, 144, 144);
      font-size: 3.7333vw;
    }
  }
  
}
</style>
